// CommonJS Syntax Version - For Gatsby

const supportedLanguages = [
  { code: "de", title: "Deutsch" },
  { code: "en", title: "English" },
  { code: "fr", title: "Français" },
];

const defaultLanguageCode = "en";

function getLanguageCodes(data) {
  return data.map((lang) => lang.code);
}

function getLanguageDataForCode(data, code) {
  return data.find((lang) => lang.code === code);
}

function getBasePathForLanguage(lang) {
  return lang && lang !== "de" ? `/${lang}/` : "/";
}

function getTemplateIdForLanguage(lang) {
  return `page-template-${lang}`;
}

function getPageTemplateIdForLanguage(lang) {
  return `page-template-${lang}`;
}

function getPostTemplateIdForLanguage(lang) {
  return `post-template-${lang}`;
}

function getStartPageTemplateIdForLanguage(lang) {
  return `start-page-${lang}`;
}

function getSiteSettingsTemplateIdForLanguage(lang) {
  return `site-settings-${lang}`;
}

// Export the functions and constants using CommonJS syntax
module.exports = {
  supportedLanguages,
  defaultLanguageCode,
  getLanguageCodes,
  getLanguageDataForCode,
  getBasePathForLanguage,
  getTemplateIdForLanguage,
  getPageTemplateIdForLanguage,
  getPostTemplateIdForLanguage,
  getStartPageTemplateIdForLanguage,
  getSiteSettingsTemplateIdForLanguage,
};
