import React from "react";
import { PopoverMenu, PopoverMenuItem } from "../PopoverMenu/PopoverMenu";
import {
  getExpirationHours,
  getExpirationYears,
  COOKIE_EXPIRATION,
} from "../../utils";
import {
  supportedLanguages,
  getLanguageDataForCode,
  getBasePathForLanguage,
} from "../../../sanity/plugins/multilanguage";

import { Link as GatsbyLink } from "gatsby";
import * as styles from "./LanguageSwitch.module.scss";

export interface LanguageSwitchProps {
  availableLanguages?: string[];
  currentLanguage?: string;
  className?: string;
}

export const LanguageSwitch: React.FC<LanguageSwitchProps> = ({
  availableLanguages,
  currentLanguage,
  className,
}) => {
  const selectableLanguages = availableLanguages?.filter(
    (lang) => lang !== currentLanguage
  );
  return (
    <PopoverMenu
      triggerElement={
        <span className={styles.languageSwitchTrigger}>{currentLanguage}</span>
      }
      className={className && className}
    >
      {selectableLanguages?.map((lang, index) => {
        const langData = getLanguageDataForCode(supportedLanguages, lang);
        return (
          <GatsbyLink
            to={getBasePathForLanguage(lang)}
            key={index}
            onClick={(event) => {
              event.preventDefault();

              if (typeof window !== "undefined") {
                //If user switch the language from landing page we have to make sure it will use the same language on application,
                // so we will set 24 hour cookie the app is working with.
                document.cookie = `preferredLang=${lang}; path=/; expires=${getExpirationYears(
                  COOKIE_EXPIRATION.LANDING_PAGE_PREFERRED_LANGUAGE_YEARS
                )};`; //this cookie will expire in 10 years
                document.cookie = `classpad_language=${lang}; path=/;`; //this cookie will expire in 24 hours
              }
              window.location.href = getBasePathForLanguage(lang); // Perform the navigation manually
            }}
          >
            <PopoverMenuItem title={langData.title} />
          </GatsbyLink>
        );
      })}
    </PopoverMenu>
  );
};
