import React, { useEffect } from "react";
import "../assets/_global.scss";

import { Header, HeaderData } from "../components/Header/Header";
import { Footer, FooterData } from "../components/Footer/Footer";
import { Layout } from "../components/Layout/Layout";
import { SEO, SEOData } from "../components/Seo/Seo";
import { graphql } from "gatsby";
import { getExpirationYears, COOKIE_EXPIRATION } from "../utils";
import * as styles from "./page.module.scss";
import { BlockContent } from "../components/BlockContent/BlockContent";

const { stripSlashes } = require("../lib/helpers");
const {
  getBasePathForLanguage,
  defaultLanguageCode,
} = require("../../sanity/plugins/multilanguage");

export const query = graphql`
  query PageQuery($id: String, $language: String) {
    sanityStartPage(id: { eq: $id }) {
      id
      language
      title
      _rawPageContent(resolveReferences: { maxDepth: 10 })
    }
    sanityPage(id: { eq: $id }) {
      id
      language
      title
      pageRedirect {
        redirectTarget {
          ... on SanityStartPage {
            id
            language
            slug {
              current
            }
          }
          ... on SanityPage {
            id
            language
            slug {
              current
            }
          }
          ... on SanityExternalRedirectTarget {
            url
          }
        }
      }
      _rawPageContent(resolveReferences: { maxDepth: 10 })
    }
    sanitySiteSettings(language: { eq: $language }) {
      id
      header {
        registerCta
        loginCta
        navigation {
          externalUrl
          title
          page {
            ... on SanityPage {
              language
              title
              slug {
                current
              }
            }
            ... on SanityStartPage {
              language
              title
              slug {
                current
              }
            }
          }
        }
      }
      footer {
        metaNavigation {
          externalUrl
          title
          page {
            ... on SanityPage {
              title
              language
              slug {
                current
              }
            }
            ... on SanityStartPage {
              title
              language
              slug {
                current
              }
            }
          }
        }
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
      seo {
        description
        title
      }
    }
  }
`;

export type PageData = {
  id: string;
  language: string;
  title: string;
  pageContent?: any;
  pageRedirect?: any;
  _rawPageContent?: any;
};

type QueryData = {
  sanityStartPage?: PageData;
  sanityPage?: PageData;
  sanitySiteSettings?: {
    id: string;
    header: HeaderData;
    footer: FooterData;
    seo: SEOData;
  };
};

interface PageTemplateProps {
  data: QueryData;
  pageContext: {
    language: string;
    id: string;
    availableLanguages?: string[];
  };
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: { sanityPage, sanityStartPage, sanitySiteSettings },
  pageContext: { language, availableLanguages },
}) => {
  const pageData = sanityPage || sanityStartPage;

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);

      let setLanguage = urlParams.get("setLanguage");
      let browserLang = navigator.language.split("-")[0]; // Extract "fr" from "fr-FR"
      //If the browser language is not in the available list we use default language as browser language
      if (!availableLanguages?.includes(browserLang))
        browserLang = defaultLanguageCode;
      let preferredLang =
        document.cookie
          .split("; ")
          .find((row) => row.startsWith("preferredLang="))
          ?.split("=")[1] || browserLang;
      let classpad_language =
        document.cookie
          .split("; ")
          .find((row) => row.startsWith("classpad_language="))
          ?.split("=")[1] || browserLang;

      //When we have language as a parameter we have to change cookies and set the right language
      if (setLanguage) {
        document.cookie = `preferredLang=${setLanguage}; path=/; expires=${getExpirationYears(
          COOKIE_EXPIRATION.LANDING_PAGE_PREFERRED_LANGUAGE_YEARS
        )};`; //this cookie will expire in 10 years
        document.cookie = `classpad_language=${setLanguage}; path=/;`; //this cookie will expire in 24 hours
        preferredLang = setLanguage;
      }

      //In order to sync the language with the CPA application we will check the classpad_language cookie, if exist we will use that language.
      if (classpad_language) {
        document.cookie = `preferredLang=${classpad_language}; path=/; expires=${getExpirationYears(
          COOKIE_EXPIRATION.LANDING_PAGE_PREFERRED_LANGUAGE_YEARS
        )};`; //this cookie will expire in 10 years
        preferredLang = classpad_language;
      }

      const storedLang = preferredLang || browserLang;

      // Check if stored language or browser languages are included in the list, if yes then we have to redirect to that page.
      const shouldRedirect =
        language !== storedLang && availableLanguages?.includes(storedLang);

      if (shouldRedirect) {
        // Construct the new URL with the correct language prefix
        const newPath = getBasePathForLanguage(storedLang);

        // Redirect to the correct language version
        window.location.replace(newPath);
      }
    }
  }, []);

  const redirectPath = getRedirectPath(pageData?.pageRedirect);
  if (redirectPath) {
    if (typeof window !== "undefined") {
      window.location.replace(redirectPath);
    }
  }

  return (
    <Layout>
      <SEO
        pageTitle={pageData?.title}
        data={sanitySiteSettings?.seo}
        language={language}
      />
      <Header
        language={language}
        data={sanitySiteSettings?.header}
        availableLanguages={availableLanguages}
      />
      <main className={styles.main}>
        <BlockContent
          blocks={pageData?._rawPageContent || pageData?.pageContent}
          language={language}
        />
      </main>
      <Footer language={language} data={sanitySiteSettings?.footer} />
    </Layout>
  );
};

const getRedirectPath = (pageRedirect: any) => {
  const redirectItem = pageRedirect?.redirectTarget[0];

  if (!redirectItem) {
    return false;
  } else if (redirectItem?.url) {
    return redirectItem.url;
  } else if (redirectItem?.slug?.current) {
    const path = redirectItem.slug.current;
    return getBasePathForLanguage(redirectItem?.language) + stripSlashes(path);
  } else {
    return false;
  }
};

export default PageTemplate;
