// config.ts
export const COOKIE_EXPIRATION = {
  LANDING_PAGE_PREFERRED_LANGUAGE_YEARS: 10, // 10 year
  CLASSPAD_LANGUAGE_HOURS: 24, // 24 hours
};

/**
 * Returns a Date object set `years` into the future.
 */
export function getExpirationYears(years: number): string {
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + years);
  return expires.toUTCString();
}

/**
 * Returns a Date object set `hours` into the future.
 */
export function getExpirationHours(hours: number): string {
  const expires = new Date();
  expires.setTime(expires.getTime() + hours * 60 * 60 * 1000);
  return expires.toUTCString();
}
